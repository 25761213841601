import React from "react"
import { graphql} from 'gatsby'
import Layout from "../components/layout"
import Seo from "../components/seo"
import {Top, TopImg, Title, InfoBlock, Info, InfoTitle, BtnGroup, Button, Dot, Content, ImageBlock, Para, ContentImg} from "../styles/marineStyles"
import NewDock from "../images/newDock.png"
import BackImg from "../images/back.png"
import Dock from "../images/deck.png"
import ContactForm from "../components/contact"

export default class DockConstruction extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      submitted: false
    }
  }
  
render() {
  return (
    <Layout>
      <Seo 
        title="Dock Construction Services"
        description="Jakes on the Lakes provides many services including dock building. We offer cedar float docks, poly float docks, Cedar-Poly float dock hybrid docks, and pier docks."
        keywords={["Jake On The Lakes", "Dock Construction Services", "Coeur d’Alene Lake", "Hauser Lake", "Newman Lake", "Priest Lake", "Pend Oreille Lake", "Cocolalla Lake", "Spokane River", "Northern Idaho", "Expertise In The Northwest", process.env.DRUPAL_WEBFORM_ENDPOINT, process.env.GATSBY_PHONE_NUMBER]}
      />
      <Top>
        <Title>NEW DOCK CONSTRUCTION</Title>
        <div><Dot/><Dot/><Dot/></div>
        <TopImg style={{backgroundImage: `url(${NewDock})`}}></TopImg>
      </Top>
      <InfoBlock style={{backgroundImage: `url(${BackImg})`}}>
        <Info>
          <InfoTitle>EXPERTISE IN THE NORTHWEST</InfoTitle>
          <div><Dot/><Dot/><Dot/></div>
          <p style={{fontSize: "20px"}}>It's hard to find long lasting, quality docks. Jakes on the Lakes provides many services including dock building. We offer cedar float docks, poly float docks, Cedar-Poly float dock hybrid docks, and pier docks. Whether you need to build, install, or replace, Jakes on the Lakes is here for your summer 2021 dock needs.</p>
        </Info>
        <BtnGroup>
          <Button to="/our-process/">OUR PROCESS</Button>
        </BtnGroup>
      </InfoBlock>
      <Content style={{marginTop: "-12%"}}>
        <Para>Polyethylene is a plastic material that is used in dock building. Poly floats docks are durable, steady, peel, splinter and rot resistant and not harmful to the animals. Cedar wood thrives in moisture and won't warp because of it. Cedar also has a natural resistance to bugs and doesn't require any sort of chemical treatment. Poly-Cedar hybrid docks have the benefits of both worlds. These dock materials are some of the best options for Northern Idaho. Whether you're looking for a simple float dock, or a pile driven dock we have them.<br/><br/>With fifteen years of experience and knowledge, Jakes on the Lakes can provide you with everything you're looking for in a dock builder. Whether you are looking for a dock for your property on Coeur d’Alene Lake, Hauser Lake, Newman Lake, Priest Lake, Pend Oreille Lake, Cocolalla Lake, Liberty Lake, or Spokane River, Jakes on the Lakes is there for you.</Para>
        <ImageBlock>
          <ContentImg src={Dock} alt="New Dock Replacement"/>
        </ImageBlock>
      </Content>
      <ContactForm data={this.props.data} />
    </Layout>
    )
  }
}

export const query = graphql`
query {
  webformWebform(drupal_internal__id: { eq: "9gk5ucvtcrek9dgqtqq28a8fkvvhkdb2" }) {
    drupal_internal__id
    elements {
        name
        type
        attributes {
          name
          value
        }
    }
  }
}
`
